export const columns = [
  {
    align: 'center',
    title: '运营人',
    dataIndex: 'operatorName',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    title: '迟完成计划次数',
    sorter: true,
    align: 'center',
    dataIndex: 'latePublishCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    title: '迟完成计划率',
    sorter: true,
    align: 'center',
    dataIndex: 'latePublishRate',
    customRender: (text) => {
      return `${text || 0}%`;
    },
  },
  {
    title: '未完成计划次数',
    sorter: true,
    align: 'center',
    dataIndex: 'unpublishCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    title: '未完成计划率',
    sorter: true,
    align: 'center',
    dataIndex: 'unpublishRate',
    customRender: (text) => {
      return `${text || 0}%`;
    },
  },
  {
    title: '总次数',
    align: 'center',
    dataIndex: 'eventCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    align: 'center',
    title: '操作',
    width: 160,
    scopedSlots: { customRender: 'operation' },
  },
];

export const historyColumns = [
  {
    title: '事件',
    scopedSlots: { customRender: 'event' },
  },
  {
    align: 'center',
    title: '运营人',
    dataIndex: 'operatorName',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    align: 'center',
    title: '对应计划',
    dataIndex: 'planDate',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    title: '选题',
    scopedSlots: { customRender: 'topicSelectionTagList' },
  },
  {
    title: '媒体号',
    scopedSlots: { customRender: 'mediaAccount' },
  },

  {
    title: '关联成员',
    scopedSlots: { customRender: 'relate' },
  },
  {
    align: 'center',
    title: '记录时间',
    dataIndex: 'eventTime',
    customRender: (record) => {
      return record || '-';
    },
  },
];
