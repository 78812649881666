import request from '@/utils/request';
import { BFZ_BFF, AFT_AIGC, AFT_CONTENT } from '@/constant/api';

export default {
  // 查询小红书人员
  getTeamMember(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operator/page',
      method: 'get',
      params,
    });
  },

  // 获取品牌管理列表
  getBrandManageList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/principal/page',
      method: 'get',
      params,
    });
  },

  // 获取团队管理列表
  getTeamManageList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operatorGroup/page',
      method: 'get',
      params,
    });
  },

  // 获取发布/计划统计列表
  getPublishStatisticsList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/noteEvent/statisticPage',
      method: 'post',
      data,
    });
  },

  // 获取发布/计划事件列表
  getPublishStatisticsEventList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/noteEvent/page',
      method: 'post',
      data,
    });
  },
  // 获取小红书主页链接
  getXhsAccountPage(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/get/user/profile',
      method: 'get',
      params,
    });
  },

  // 导出发布统计
  downloadPublishStatistics(data) {
    return request({
      baseURL: BFZ_BFF,
      responseType: 'blob',
      url: `/xhs/noteEvent/exportStatistic`,
      method: 'post',
      data,
    });
  },

  // 获取数据更新时间
  getUpdateTime(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/noteEvent/getUpdateTime',
      method: 'get',
      params,
    });
  },
};
